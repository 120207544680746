interface ResponseMessage {
  title?: string;
  options?: string[];
  actions?: Array<{
    title: string;
    link: string;
  }>;
  startDateTime?: string | null;
  endDateTime?: string | null;
  text?: string;
  [key: string]: any;
}

export interface ParsedResponse {
  title?: string;
  options?: string[];
  actions?: Array<{
    title: string;
    link: string;
  }>;
  startDateTime?: string | null;
  endDateTime?: string | null;
  text?: string;
  isRawText?: boolean;
}

export const parseApiResponse = (response: any): ParsedResponse => {
  try {
    // Get the message text from the response structure
    console.log('response', response);  
    const messageText = response?.outputs?.[0]?.outputs?.[0]?.results?.message?.text || '';
    console.log('messageText', messageText);  
    // If empty, return empty object
    if (!messageText) return { text: '' };

    // Check if the message contains markdown JSON syntax
    const jsonMatch = messageText.match(/```json\n([\s\S]*?)\n```/);
    
    if (jsonMatch) {
      // Extract the JSON string and parse it
      try {
        const jsonStr = jsonMatch[1].trim();
        const parsedJson: ResponseMessage = JSON.parse(jsonStr);
        
        // Return the structured format
        return {
          title: parsedJson.title,
          options: parsedJson.options,
          actions: parsedJson.actions,
          startDateTime: parsedJson.startDateTime,
          endDateTime: parsedJson.endDateTime,
          text: parsedJson.text
        };
      } catch (jsonError) {
        // If JSON parsing fails, return the cleaned text without markdown
        return {
          text: messageText.replace(/```json\n|```/g, '').trim(),
          isRawText: true
        };
      }
    } else {
      // Try to parse the text as JSON
      try {
        const parsedJson: ResponseMessage = JSON.parse(messageText);
        console.log('parsedJson', parsedJson);
        return {
          title: parsedJson.title, 
          options: parsedJson.options,
          actions: parsedJson.actions,
          startDateTime: parsedJson.startDateTime,
          endDateTime: parsedJson.endDateTime,
          text: parsedJson.text
        };
      } catch (jsonError) {
        console.error('Error parsing JSON:', jsonError);
      }
    }

    // If no JSON markdown found, return the text as is
    return {
      text: messageText,
      isRawText: true
    };
  } catch (error) {
    console.error('Error parsing API response:', error);
    return {
      text: 'Sorry, I had trouble processing that response.',
      isRawText: true
    };
  }
}; 