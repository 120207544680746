import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import "./StreamChatWidget.css";
import { AIAnalyticsType, supabaseService, ThemeData } from "../services/supabase";

interface StreamChatWidgetProps {
  eventId: string;
}

interface Message {
  id: string;
  text: string;
  sender: "user" | "ai";
  timestamp: Date;
}

const API_URL = "https://langflow-w8di.onrender.com/api/v1/run/9d6f2912-de87-4f4c-b4e6-779349928b85";
const API_KEY = "sk-3_KAczluQMjPw_ZFR3-bxY6iSRG0AaTYk999GcbRoEo";

const StreamChatWidget: React.FC<StreamChatWidgetProps> = ({ eventId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [theme, setTheme] = useState<ThemeData | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [streamingMessage, setStreamingMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showWelcome, setShowWelcome] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initializeWidget = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        // First, get the place for this event
        const place = await supabaseService.getPlaceForEventId(eventId);
        if (!place) {
          throw new Error('Could not find place for this event');
        }

        // Then get the theme data for the place
        const themeData = await supabaseService.getThemeData(place.id);
        if (!themeData) {
          throw new Error('Could not find theme data for this place');
        }

        setTheme(themeData);
        // Show welcome bubble after initialization
        setShowWelcome(true);
        setTimeout(() => {
          setShowWelcome(false);
        }, 6000);
      } catch (err) {
        console.error('Error initializing widget:', err);
        setError(err instanceof Error ? err.message : 'Failed to initialize chat widget');
      } finally {
        setIsLoading(false);
      }
    };

    initializeWidget();
  }, [eventId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping, streamingMessage]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getThemeStyles = () => {
    if (!theme) return {};

    return {
      "--button-color": theme.button_color,
      "--button-text-color": theme.button_text_color,
    } as React.CSSProperties;
  };

  const processStream = async (reader: ReadableStreamDefaultReader<Uint8Array>) => {
    const decoder = new TextDecoder();
    let accumulatedMessage = "";
    let currentMessageId: string | null = null;

    try {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split("\n");

        for (const line of lines) {
          if (!line.trim()) continue;

          try {
            const data = JSON.parse(line);
            
            switch (data.event) {
              case "add_message":
                if (data.data.sender === "Machine") {
                  currentMessageId = data.data.id;
                }
                break;
              
              case "token":
                if (data.data.id === currentMessageId) {
                  accumulatedMessage += data.data.chunk;
                  setStreamingMessage(accumulatedMessage);
                }
                break;
              
              case "end":
                if (data.data.result?.message) {
                  accumulatedMessage = data.data.result.message;
                  setStreamingMessage(accumulatedMessage);
                }
                break;
            }
          } catch (e) {
            console.error("Error parsing JSON:", e);
          }
        }
      }
    } catch (error) {
      console.error("Error reading stream:", error);
    }

    return accumulatedMessage; 
  };

  const handleSendMessage = async () => {
    if (!inputValue.trim() || !theme) return;

    const userMessage: Message = {
      id: Date.now().toString(),
      text: inputValue,
      sender: "user",
      timestamp: new Date(),
    };

    supabaseService.logAIAnalytics(theme.place_id, AIAnalyticsType.CHAT_MESSAGE_SENT, inputValue);

    setMessages((prev) => [...prev, userMessage]);
    setInputValue("");
    setIsTyping(true);
    setStreamingMessage("");

    try {
      const response = await fetch(API_URL + "?stream=true", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
        },
        body: JSON.stringify({
          input_value: inputValue,
          output_type: "chat",
          input_type: "chat",
          tweaks: {
            "ChatInput-Z2wKQ": {},
            "SupabaseVectorStore-j2995": {
              place_id: theme.place_id,
            },
            "ParseData-PhaeT": {},
            "Prompt-YYpXY": {},
            "ChatOutput-weWXf": {},
            "OpenAIEmbeddings-97MHW": {},
            "OpenAIModel-1TcEd": {},
            "Memory-Kkcb6": {},
            "StoreMessage-kpLgb": {},
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (!response.body) {
        throw new Error("No response body");
      }

      const reader = response.body.getReader();
      const finalMessage = await processStream(reader);

      const aiMessage: Message = {
        id: Date.now().toString(),
        text: finalMessage || "Sorry, I couldn't process your request.",
        sender: "ai",
        timestamp: new Date(),
      };

      setMessages((prev) => [...prev, aiMessage]);
    } catch (error) {
      console.error("Error:", error);
      const errorMessage: Message = {
        id: Date.now().toString(),
        text: "Sorry, there was an error processing your message.",
        sender: "ai",
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
      setStreamingMessage("");
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Show loading spinner while initializing
  if (isLoading) {
    return (
      <div className="chat-widget-loading">
        <div className="loading-spinner"></div>
        <p>Loading chat widget...</p>
      </div>
    );
  }

  // Show error message if initialization failed
  if (error) {
    return (
      <div className="chat-widget-error">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="chat-widget-container" style={getThemeStyles()}>
      <div className={`chat-window ${isOpen ? "open" : ""}`}>
        <div className="chat-header">
          {theme?.ai_chat_logo && (
            <img src={theme.ai_chat_logo} alt="Chat Logo" className="chat-logo" />
          )}
          <h3>{theme?.slogan_text || "AI Chat"}</h3>
          <button className="close-button" onClick={() => setIsOpen(false)}>
            ×
          </button>
        </div>
        <div className="chat-messages">
          {theme?.ai_chat_greeting && messages.length === 0 && (
            <div className="chat-greeting">{theme.ai_chat_greeting}</div>
          )}
          {messages.map((message) => (
            <div
              key={message.id}
              className={`message ${
                message.sender === "user" ? "user-message" : "ai-message"
              }`}
            >
              <div className="message-content">
                {message.sender === "user" ? (
                  message.text
                ) : (
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                )}
              </div>
            </div>
          ))}
          {isTyping && streamingMessage && (
            <div className="message ai-message">
              <div className="message-content">
                <ReactMarkdown>{streamingMessage}</ReactMarkdown>
              </div>
            </div>
          )}
          {isTyping && !streamingMessage && (
            <div className="message ai-message">
              <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="chat-input">
          <input
            type="text"
            placeholder="Type your message..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
      <div className={`welcome-bubble ${showWelcome ? 'show' : ''}`}>
        Hei! Jeg heter Ask. Jeg kan hjelpe deg med spørsmål, veiledning eller informasjon. Klikk her for å starte en prat!
      </div>
      <button className="chat-toggle-button" onClick={() => {
        setIsOpen(!isOpen);
        if (!isOpen) {
          setShowWelcome(false);
        }
      }}>
        {theme?.ai_chat_logo ? (
          <img src={theme.ai_chat_logo} alt="Place Logo" className="place-logo" />
        ) : isOpen ? (
          "×"
        ) : (
          "💬"
        )}
      </button>
    </div>
  );
};

export default StreamChatWidget; 