import React, { useState, useEffect, useRef } from "react";
import "./ChatWidget.css";
import { AIAnalyticsType, supabaseService, ThemeData } from "../services/supabase";
import { parseApiResponse, ParsedResponse } from "../utils/responseParser";
import ChatMessage from "./ChatMessage";

interface ChatWidgetProps {
	eventId: string;
}

interface Message {
	id: string;
	text: string | ParsedResponse;
	sender: "user" | "ai";
	timestamp: Date;
}

const API_URL =
	"https://langflow-w8di.onrender.com/api/v1/run/8387cb2a-3d56-411b-acf6-a6d2b022c348";
const API_KEY = "sk-3_KAczluQMjPw_ZFR3-bxY6iSRG0AaTYk999GcbRoEo";
//const AUTH_TOKEN = import.meta.env.VITE_LANGFLOW_AUTH_TOKEN;

const ChatWidget: React.FC<ChatWidgetProps> = ({ eventId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [theme, setTheme] = useState<ThemeData | null>(null);
	const [messages, setMessages] = useState<Message[]>([]);
	const [inputValue, setInputValue] = useState("");
	const [isTyping, setIsTyping] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [showWelcome, setShowWelcome] = useState(false);
	const messagesEndRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const initializeWidget = async () => {
			try {
				setIsLoading(true); 
				setError(null);
				
				// First, get the place for this event
				const place = await supabaseService.getPlaceForEventId(eventId);
				if (!place) {
					throw new Error('Could not find place for this event');
				}

				// Then get the theme data for the place
				const themeData = await supabaseService.getThemeData(place.id);
				if (!themeData) {
					throw new Error('Could not find theme data for this place');
				}

				setTheme(themeData);
				// Show welcome bubble after initialization
				setShowWelcome(true);
				setTimeout(() => {
					setShowWelcome(false); 
				}, 6000);
			} catch (err) {
				console.error('Error initializing widget:', err);
				setError(err instanceof Error ? err.message : 'Failed to initialize chat widget');
			} finally {
				setIsLoading(false);
			}
		};

		initializeWidget();
	}, [eventId]);

	useEffect(() => {
		scrollToBottom();
	}, [messages, isTyping]);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	const getThemeStyles = () => {
		if (!theme) return {};

		return {
			"--button-color": theme.button_color,
			"--button-text-color": theme.button_text_color,
		} as React.CSSProperties;
	};

	const handleSendMessage = async () => {
		if (!inputValue.trim() || !theme) return;

		const userMessage: Message = {
			id: Date.now().toString(),
			text: inputValue,
			sender: "user",
			timestamp: new Date(),
		};

		supabaseService.logAIAnalytics(theme.place_id, AIAnalyticsType.CHAT_MESSAGE_SENT, inputValue);

		setMessages((prev) => [...prev, userMessage]);
		setInputValue("");
		setIsTyping(true);

		try {
			const response = await fetch(API_URL + "?stream=false", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": API_KEY,
				},
				body: JSON.stringify({
					input_value: inputValue,
					output_type: "chat",
					input_type: "chat",
					tweaks: {
						"ChatInput-Z2wKQ": {},
						"SupabaseVectorStore-j2995": {
							place_id: theme.place_id,
						},
						"ParseData-PhaeT": {},
						"Prompt-YYpXY": {},
						"ChatOutput-weWXf": {},
						"OpenAIEmbeddings-97MHW": {},
						"OpenAIModel-1TcEd": {},
						"Memory-Kkcb6": {},
						"StoreMessage-kpLgb": {},
					},
				}),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			const parsedMessage = parseApiResponse(data);

			const aiMessage: Message = {
				id: Date.now().toString(),
				text: parsedMessage || "Sorry, I couldn't process your request.",
				sender: "ai",
				timestamp: new Date(),
			};

			setMessages((prev) => [...prev, aiMessage]);
		} catch (error) {
			console.error("Error:", error);
			const errorMessage: Message = {
				id: Date.now().toString(),
				text: "Sorry, there was an error processing your message.",
				sender: "ai",
				timestamp: new Date(),
			};
			setMessages((prev) => [...prev, errorMessage]);
		} finally {
			setIsTyping(false);
		}
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleSendMessage();
		}
	};

	// Show loading spinner while initializing
	if (isLoading) {
		return (
			<div className="chat-widget-loading">
				<div className="loading-spinner"></div>
				<p>Loading chat widget...</p>
			</div>
		);
	}

	// Show error message if initialization failed
	if (error) {
		return (
			<div className="chat-widget-error">
				<p>{error}</p>
			</div>
		);
	}

	return (
		<div className="chat-widget-container" style={getThemeStyles()}>
			<div className={`chat-window ${isOpen ? "open" : ""}`}>
				<div className="chat-header">
					{theme?.ai_chat_logo && (
						<img
							src={theme.ai_chat_logo}
							alt="Chat Logo"
							className="chat-logo"
						/>
					)}
					<h3>{theme?.slogan_text || "AI Chat"}</h3>
					<button className="close-button" onClick={() => setIsOpen(false)}>
						×
					</button>
				</div>
				<div className="chat-messages">
					{theme?.ai_chat_greeting && messages.length === 0 && (
						<div className="chat-greeting">{theme.ai_chat_greeting}</div>
					)}
					{messages.map((message) => (
						<ChatMessage
							key={message.id}
							message={message}
							themeColor={theme?.button_color || '#007bff'}
						/>
					))}
					{isTyping && (
						<div className="message ai-message">
							<div className="typing-indicator">
								<span></span>
								<span></span>
								<span></span>
							</div>
						</div>
					)}
					<div ref={messagesEndRef} />
				</div>
				<div className="chat-input">
					<input
						type="text"
						placeholder="Type your message..."
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						onKeyPress={handleKeyPress}
					/>
					<button onClick={handleSendMessage}>Send</button>
				</div>
			</div>
			<div className={`welcome-bubble ${showWelcome ? 'show' : ''}`}>
				Hei! Jeg heter Ask. Jeg kan hjelpe deg med spørsmål, veiledning eller informasjon. Klikk her for å starte en prat!
			</div>
			<button className="chat-toggle-button" onClick={() => {
				setIsOpen(!isOpen);
				if (!isOpen) {
					setShowWelcome(false);
				}
			}}>
				{theme?.ai_chat_logo ? (
					<img src={theme.ai_chat_logo} alt="Place Logo" className="place-logo" />
				) : isOpen ? (
					"×"
				) : (
					"💬"
				)}
			</button>
		</div>
	);
};

export default ChatWidget;
