/* eslint-disable @typescript-eslint/no-explicit-any */
import { createClient } from "@supabase/supabase-js";

export interface ThemeData {
	place_id: string;
	place_logo: string;
	button_color: string;
	button_text_color: string;
	ai_chat_logo: string;
	ai_chat_greeting: string;
	slogan_text: string;
	created_at: string;
}

export enum AIAnalyticsType {
	CHAT_OPENED = "CHAT_OPENED",
	CHAT_CLOSED = "CHAT_CLOSED",
	CHAT_MESSAGE_SENT = "CHAT_MESSAGE_SENT",
}

interface PlaceData {
	id: string;
	name: string;
	// Add other place fields as needed
}

class SupabaseService {
	private static instance: SupabaseService;
	private supabase;

	private constructor() {
		this.supabase = createClient(
			"https://erlrzretcsxoskhdifia.supabase.co",
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVybHJ6cmV0Y3N4b3NraGRpZmlhIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzM2MjAzOTAsImV4cCI6MTk4OTE5NjM5MH0.mjcikuKa4zKptYLrUmMFixs2KtRwlCFJBgAl2rFeTGc"
		);
	}

	public static getInstance(): SupabaseService {
		if (!SupabaseService.instance) {
			SupabaseService.instance = new SupabaseService();
		}
		return SupabaseService.instance;
	}

	public async getPlaceForEventId(eventId: string): Promise<PlaceData | null> {
		try {
			console.log("Fetching place for event ID:", eventId);
			const { data, error } = await this.supabase
				.from("places")
				.select("*")
				.eq("name", `Place ${eventId}`)
				.single();

			if (error) {
				console.error("Error fetching place data:", error);
				return null;
			}

			console.log("Found place:", data);
			return data;
		} catch (error) {
			console.error("Error in getPlaceForEventId:", error);
			return null;
		}
	}

	public async getThemeData(placeId: string): Promise<ThemeData | null> {
		try {
			console.log("Fetching theme data for place ID:", placeId);
			const { data, error } = await this.supabase
				.from("place_branding")
				.select("*")
				.eq("place_id", placeId)
				.single();

			if (error) {
				console.error("Error fetching theme data:", error);
				return null;
			}

			console.log("Found theme data:", data);
			return data;
		} catch (error) {
			console.error("Error in getThemeData:", error);
			return null;
		}
	}

	public async logAIAnalytics(
		placeId: string,
		type: AIAnalyticsType,
		actionValue?: any
	) {
		const { data, error } = await this.supabase.from("ai_analytics").insert([
			{
				place_id: placeId,
				action_type: type,
				action_value: actionValue,
			},
		]);
    console.log(data);

		if (error) {
			console.error("Error logging AI analytics", error);
		}
	}
}
export const supabaseService = SupabaseService.getInstance();
