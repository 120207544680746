import React from 'react';
import { createRoot } from 'react-dom/client';
import ChatWidget from './components/ChatWidget';
import StreamChatWidget from './components/StreamChatWidget';

// Define the global configuration interface
interface ChatWidgetConfig {
  eventId: string;
  type?: 'stream' | 'regular';
}

// Define the global window interface
declare global {
  interface Window {
    NEAT_CHAT_CONFIG: ChatWidgetConfig;
  }
}

// Create and inject CSS link
const injectCSS = () => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = 'https://neat-chat-widget.vercel.app/neat-chat.css';
  document.head.appendChild(link);
};

// Create a container for the widget
const createWidgetContainer = () => {
  console.log('[NeatChat] Creating widget container');
  const existingContainer = document.getElementById('neat-chat-widget');
  if (existingContainer) {
    console.log('[NeatChat] Widget container already exists');
    return existingContainer;
  }

  const container = document.createElement('div');
  container.id = 'neat-chat-widget';
  container.style.position = 'fixed';
  container.style.bottom = '20px';
  container.style.right = '20px';
  container.style.zIndex = '999999';
  document.body.appendChild(container);
  console.log('[NeatChat] Widget container created and added to body');
  return container;
};

// Initialize the widget
const initializeWidget = () => {
  console.log('[NeatChat] Starting widget initialization');
  
  try {
    // Inject CSS first
    injectCSS();
    console.log('[NeatChat] CSS injected');

    // Check if React is available
    if (typeof React === 'undefined') {
      throw new Error('React is not loaded');
    }
    console.log('[NeatChat] React is loaded');

    // Check if createRoot is available
    if (typeof createRoot === 'undefined') {
      throw new Error('ReactDOM is not loaded');
    }
    console.log('[NeatChat] ReactDOM is loaded');

    const config = window.NEAT_CHAT_CONFIG;
    console.log('[NeatChat] Config received:', config);
    
    if (!config) {
      throw new Error('NEAT_CHAT_CONFIG is not defined');
    }

    if (!config.eventId) {
      throw new Error('eventId is required in NEAT_CHAT_CONFIG');
    }

    const container = createWidgetContainer();
    console.log('[NeatChat] Creating React root');
    const root = createRoot(container);

    // Render the appropriate widget based on type
    if (config.type === 'stream') {
      console.log('[NeatChat] Rendering StreamChatWidget');
      root.render(React.createElement(StreamChatWidget, { eventId: config.eventId }));
    } else {
      console.log('[NeatChat] Rendering ChatWidget');
      root.render(React.createElement(ChatWidget, { eventId: config.eventId }));
    }

    console.log('[NeatChat] Widget initialization complete');
  } catch (error: unknown) {
    console.error('[NeatChat] Error during widget initialization:', error);
    // Create error container if widget container doesn't exist
    const errorContainer = document.createElement('div');
    errorContainer.id = 'neat-chat-error';
    errorContainer.style.position = 'fixed';
    errorContainer.style.bottom = '20px';
    errorContainer.style.right = '20px';
    errorContainer.style.padding = '10px';
    errorContainer.style.backgroundColor = '#ffebee';
    errorContainer.style.border = '1px solid #ef5350';
    errorContainer.style.borderRadius = '4px';
    errorContainer.style.zIndex = '999999';
    errorContainer.innerHTML = `Error initializing chat widget: ${error instanceof Error ? error.message : 'Unknown error'}`;
    document.body.appendChild(errorContainer);
  }
};

// Wait for DOM to be ready
if (document.readyState === 'loading') {
  console.log('[NeatChat] DOM is loading, waiting for DOMContentLoaded');
  document.addEventListener('DOMContentLoaded', () => {
    console.log('[NeatChat] DOM loaded, initializing widget');
    initializeWidget();
  });
} else {
  console.log('[NeatChat] DOM already loaded, initializing widget');
  initializeWidget();
} 