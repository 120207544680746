import React from "react";
import { ParsedResponse } from "../utils/responseParser";
import "./ChatMessage.css";

interface ChatMessageProps {
	message: {
		id: string;
		text: string | ParsedResponse;
		sender: "user" | "ai";
		timestamp: Date;
	};
	themeColor: string;
}

const formatDate = (dateString: string) => {
	const date = new Date(dateString);
	return date.toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "short",
		year: "numeric",
	});
};

const ChatMessage: React.FC<ChatMessageProps> = ({ message, themeColor }) => {
	if (message.sender === "user") {
		return (
			<div className="message user-message">
				<div className="message-content">{message.text as string}</div>
			</div>
		);
	}

	const response = message.text as ParsedResponse;

	if (response.isRawText) {
		return (
			<div className="message ai-message">
				<div className="message-content">{response.text}</div>
			</div>
		);
	}

	return (
		<div className="message ai-message">
			<div className="message-content structured-content">
				{response.title && (
					<div className="message-title">
						<h4>{response.title}</h4>
						{(response.startDateTime || response.endDateTime) && (
							<div className="date-pills">
								{response.startDateTime && (
									<span
										className="date-pill"
										style={{
											backgroundColor: `${themeColor}15`,
											color: themeColor,
										}}
									>
										<i className="calendar-icon">📅</i>
										<span className="date-label">Start:</span>
										{formatDate(response.startDateTime)}
									</span>
								)}
								{response.endDateTime && (
									<span
										className="date-pill"
										style={{
											backgroundColor: `${themeColor}15`,
											color: themeColor,
										}}
									>
										<i className="calendar-icon">📅</i>
										<span className="date-label">End:</span>
										{formatDate(response.endDateTime)}
									</span>
								)}
							</div>
						)}
					</div>
				)}

				{response.options && response.options.length > 0 && (
					<div className="options-container">
						{response.options.map((option, index) => (
							<div key={index} className="option-card">
								{option}
							</div>
						))}
					</div>
				)}

				{response.text && !response.title && (
					<div className="message-text">{response.text}</div>
				)}

				{response.actions && response.actions.length > 0 && (
					<div className="actions-container">
						{response.actions.map((action, index) => {
              console.log(action);
							return <a
								key={index}
								href={action.link}
								target="_blank"
								rel="noopener noreferrer"
								className="action-button"
								style={{ backgroundColor: themeColor , color: "white"}}
							>
								{action.title}
							</a>;
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default ChatMessage;
